@import "~@angular/material/theming";

@import "~@alfresco/adf-core/theming";

@include mat-core($alfresco-typography);

$primary: mat-palette($alfresco-accent-orange);
$accent: mat-palette($alfresco-accent-purple);
$warn: mat-palette($alfresco-warn);
$theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($theme);
@include adf-core-theme($theme);

body,
html {
    margin: 0;
    height: 100%;
    overflow: hidden;
    font-size: mat-font-size($alfresco-typography, body-1);
    // font-family: mat-font-family($alfresco-typography);
    // line-height: mat-line-height($alfresco-typography, body-1);
    // font-size: var(--bs-body-font-size) !important;

    font-family: var(--bs-body-font-family) !important;
    font-weight: var(--bs-body-font-weight) !important;
    line-height: var(--bs-body-line-height) !important;
    color: var(--bs-body-color) !important;
    --adf-user-info-color: #f2cd00;
    --adf-header-text-color: #fff;
    --ua-primary-background-color-green: #275d38;
    --theme-text-color: #fff;
    --ua-primary-background-yellow: #f2cd00;
}

body {
    overflow: auto;

    footer .final {
        border-top: 2px solid #fff !important;
        padding: 2rem 0 1rem;
    }
}
